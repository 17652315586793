body {
  margin: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  scroll-behavior: smooth;
}
.dice-logo {
  width: 400px;
}
.slogan {
  font-style: italic;
  margin-bottom: 10px;
}
hr {
  border: 1px solid #ffffff;
  margin: 20px auto;
  width: 50%;
}
.description {
  margin-bottom: 50px;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  padding: 20px;
}
.list {
  list-style-type: none;
  padding: 0;
}
.list li {
  margin: 10px 0;
}
.card2 {
  margin-left: 0px;
}
.footer-nav {
  display: flex;
  justify-content: center;
  padding: 1em;
  padding-top: 250px;
  /* background-color: #e6f2ff; */
}
.footer-nav a {
  margin: 0 10px;
  text-decoration: none;
  color: black;
}
.footer-nav a:hover {
  text-decoration: underline;
}
.copyright {
  /* background-color: #e6f2ff; */
  text-align: center;
  padding-top: 5px;
  padding-bottom: 30px;
  margin: 0px;
}
.terms h1, .terms h2 {
  color: #333;
  text-align: left;
}
.terms p {
  margin-bottom: 15px;
  text-align: left;
}
.aboutus p {
  text-align: left;
}
.cardsec {
   flex-direction: column;
}
@media (min-width: 700px) {
  .cardsec {
     flex-direction: row;
  }
  .card2 {
    margin-left: 30px !important;
  }
}
@media only screen and (max-width: 440px) {
  .dice-logo {
    width: 340px !important;
  }
} 
@media only screen and (max-width: 321px) {
  .dice-logo {
    width: 300px !important;
  }
} 